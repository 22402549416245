nav{
    position: relative;
    width: 100%;
    height: 50px;
    padding: 0 5px;
}
nav >ul, nav>ul>ol{
    list-style: none;
    height: 100%;
    display: flex;
    align-items: center;
}
nav>ul{
justify-content: space-between;
}
nav li{
    cursor: pointer;
}
nav ul>ol:last-child>li{
    margin: 0 10px;
}
nav ul>ol>li:first-child{
    margin-right:13px;
}
#noti_box{
    position: absolute;
    right: 10px;
    top: 50px;
    width:300px;
    height: 400px;
    background-color: var(--main_bg);
    border-radius: 20px;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    display: none;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}