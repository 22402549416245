.body{
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}
.body>div{
    height: 100%;
}
@media only screen and (max-width: 510px){
    .body{
        flex-flow: column-reverse;
        align-items: center;
    }
    .body>div{
        width: 100%;
    }
    .body>div:first-child{
        height: 50px;
        z-index: 1;
    }
    .body>div:nth-child(2){
        height: 100% ;
        margin-bottom: 50px;
    }
}

