#options{
    position: sticky;
    top: 0;
    width: 100%;
    list-style: none;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 5px 10px;
    z-index: 1;
    /* From https://css.glass */
background: rgba(255, 255, 255, 0.13);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5.3px);
-webkit-backdrop-filter: blur(5.3px);
}
#options>div{
    height: 30px;
    margin: 0 5px;
}
#options>div:last-child>div{
    height: 100%;
    float: left;
}
.format>img{
    margin: 0 5px;
    cursor: pointer;
}

.filter{
    background-color: var(--main_bg);
    border-radius: 10px;
    margin: 0 10px;
    padding-right: 10px;
    overflow: hidden;
}
.filter>*{
    height: 100%;
    float: left;
}
.filter>input{
border: none;
margin-left: 10px;
background-color: var(--main_bg);
color: var(--main_text);
}
.filter>input:focus{
    outline: none;
}
.filter path{
    stroke: var(--main_text);
}
.filter>.sf{
    cursor: pointer;
}
#mobile_srch_icn{
    display: none;
}
.short>*{
    border-radius: 10px;
    background-color: var(--main_bg);
}
#sopt{
    list-style: none;
    margin-top: 5px;
    overflow: hidden;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.sopt_tog{
    height: 0;
}
#sopt li{
    padding: 5px 10px;
    cursor: pointer;
}
#sopt a{
    text-decoration: none;
    color: var(--main_text);
}
.short>p{
padding: 5px;
color: #7c7c7c;
}

.short>p>samp{
    padding: 5px 10px;
 cursor: pointer;
    border-radius: 10px;
    background-color: var(--theme_color);
    color: var(--main_bg);
}
.short>p:nth-child(2){
    display: none;
}
#feeds{
    margin-top:10px;
    z-index: 0;
}
.grid{
    display: grid;
    width: auto;
    grid-template-columns: auto auto auto auto auto;
}
.list{
    display: flex;
    flex-direction: column;
}
.feed{
    cursor: pointer;
    padding: 20px;
    margin: 5px;
    border-radius: 10px;
    background-color: var(--deep_bg);
    transition: 0.2s ease-out;
    text-decoration: none;
    color: var(--main_text);
}
.feed:hover{
    transform: scale(1.03);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.feed>div{
    display: flex;
    justify-content: space-between;
}
.srno{
    background-color: #d3f4eb;
    color:var(--theme_color);
    height: 20px;
    min-width: 20px;
    font: 0.8em sans-serif;
    max-width: auto;
    padding: 3px;
    border-radius: 10px;
    text-align: center;
}
.f_data,.f_data samp{
    font-size: 10px;
}
.f_data h1:nth-child(2),.pverses{
    text-align: right;
}
#p_feed .f_data{
    padding: 10px;
    margin: 5px 0;
    border-radius: 20px;
    background-color: var(--back_bg);
}
.f_top{
    margin-bottom: 8px;
}
.para_scroll{
    width: 100%;
    height: 200px;
    overflow-y: scroll;
    display: flex;
    flex-flow: column;

}
.para_scroll::-webkit-scrollbar{
    display: none;
}
.para_scroll>div{
    width: 100%;

}
.para_scroll h1:nth-child(2),.pverses{
    line-height: 10px;
    font-size: 10px;
}
#likeSVG{
    cursor: pointer;
}
@media only screen and (max-width: 1200px){
    .grid{grid-template-columns: auto auto auto auto;}
}
@media only screen and (max-width: 900px){
    .grid{grid-template-columns: auto auto auto;}
}
@media only screen and (max-width: 800px){
    .grid{grid-template-columns: auto auto;}
    .short>p:nth-child(1){
        display: none;
    }
    .short>p:nth-child(2){
        background: none;
        display: block;
    }
}
@media only screen and (max-width: 520px){
    .grid{grid-template-columns: auto;}
    .filter>input{
        display: none;
    }
    .filter{
        padding: 0 10px;
        border-radius: 20px;
    }
    .para_scroll{
        height: auto;   
    }
    .filter>.sf{
        display: none;
    }
    #mobile_srch_icn{
        display: block;
    }
    .filter input{
        width: 100px;
    }
}