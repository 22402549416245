#ayatMarker{
    display: none;
}
.ayat_marker{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #ffffff62;
    z-index: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
.ayat_marker>.form{
    width: 300px;
    height: 200px;
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-flow: column;
    gap: 10px;
}

.ayat_marker>.form>textarea{
    max-width: 250px;
    max-height: 200px;
    border: 2px solid black;
    padding: 10px;
}
.ayat_marker>.form>input,.ayat_marker>.form>textarea{
    border: 2px solid var(--theme_color);
    padding: 10px;
}