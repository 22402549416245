@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Noto+Naskh+Arabic&family=Roboto:wght@100&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: var(--main_bg);
    color: var(--main_text);

}
#root{
    width: 100%;
    height: 100%;
}
hr{
    margin: 15px 0;
}
:root{
    --theme_color:#029b7f;
    --main_text:#000;
    --main_bg:#fff;
    --back_bg:#e7edef;
    --deep_bg:#fff;
    --anti_theme:#c2f3e6;
    --icons_color:#2C3E50;
}
.backSVG{
    cursor: pointer;
    color: var(--icons_color);
    height: 24px;
    width: 24px;
}

/* 
    --theme_color:#029b7f;
    --main_bg:#fff;
    --back_bg:#e7edef;
 */