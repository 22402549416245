.left{
    width: 50px;
}
.left>ul{
    position: relative;
    list-style: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow:column;
    align-items: center;
    margin: 5px 0;
}
.left>ul>li{
    width: 35px;
    height: 35px;
    margin: 5px 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    border-radius: 10px;
}
.left svg>path{
    fill: var(--icons_color);
}
.left .active>svg>path{
    fill: var(--theme_color);
}
@media only screen and (min-width: 510px){
    .left>ul>li:last-child{
        position: absolute;
        bottom: 0;
        margin-bottom: 15px;
    }
    .left>ul>li{
        align-items: center;
    }    
}
@media only screen and (max-width: 510px){
    .left{
        position: fixed;
        height: 50px;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: var(--main_bg);
    }
    .left>ul{
        width: 100%;
        height: 100%;
        flex-flow: row;
        justify-content: space-around;
    }

}
