#menu{
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: none;
    grid-template-columns: minmax(350px , 500px) auto;
    z-index: 2;
}
.menu_list{
    position: relative;
    height: 100%;
    background-color: var(--theme_color);
}
.menu_list>.back{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 1;
    transform: rotate(180deg);
}
.menu_list>ul{
    position: relative;
    height: 100%;
    padding-top: 5px;
    list-style: none;


}
.menu_list>ul>li{
    width: 70%;
    margin: 5px auto;
    padding: 5px;
    text-align: center;
    background-color: var(--main_bg);
    border-radius: 20px;
    cursor: pointer;
}
.menu_list>ul a{
    text-decoration: none;
    color: var(--main_text);
}
.menu_list>ul>li:first-child{
    margin-bottom: 10px;
    background-color: transparent;
    font-weight: 900;
    font-size: 1.2rem;
    cursor: default;
}
.menu_list>ul>li:last-child{
    position: absolute;
    right: 50%;
    bottom: 0;
    transform: translate(50%,0);
    background-color: #ff4d4d;
    color: var(--main_bg);
    font-weight: bolder;
}

#menu>div:first-child{
            /* From https://css.glass */
background: rgba(255, 255, 255, 0.13);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(2px);
-webkit-backdrop-filter: blur(2px);
}