#like_main #options{
    display: flex;
    flex-flow: row;
    justify-content:space-between;
    align-items: center;
}
#like_main #options>ul{
    position: relative;
    padding: 3px;
    border-radius: 20px;
    background-color: var(--main_bg);
    list-style: none;
}
#like_main #options>ul>li{
    width: 100px;
    float: left;
    text-align: center;
    padding: 3px 0;
    margin: 0 3px;
    border-radius: 30px;
    cursor: pointer;
}
#like_main #options>ul>.active{
    background-color: var(--theme_color);
    color: var(--main_bg);
}
#like_main #options>ul>li:hover:not(.active){
    background-color: var(--anti_theme);
    color: var(--theme_color);
}
#like_main #options>ul>li:last-child{
    width: 20px;
    display: none;
}
#like_main #options>ul>ol{
    position: absolute;
    right: 0;
    top: 120%;
    list-style: none;
    padding: 15px;
    background-color: var(--main_bg);
    border-radius: 20px;
    display: none;
}
@media only screen and (max-width: 510px){
    #like_main #options>ul>li:not(.active){
        display: none;
    }
    #like_main #options>ul>li:last-child{
        display: block;
    }
    }