#switch {
  cursor: pointer;
  background-color: rgba(204, 204, 204, 0.527);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50px;
}
.ban_switch {
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 100px;
  height: 50px;
}
.nav_switch {
  position: relative;
  width: 50px;
  height: 25px;
}
.ban_switch .slider{
  width: 50px;
  height: 50px;
}
.nav_switch .slider{
  width: 25px;
  height: 25px;
}
#switch .slider {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--main_bg);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50px;
  margin: 0;
}

#switch[theme="dark"] .slider{
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}
