
#setting_main{
    position: relative;
}
#setting_main>div{
    width: 100%;
    margin: 10px 0;
}
#theme_card{
    position: relative;
    width: 100%;
    height: 300px;
    border-radius: 30px;
    padding: 20px;
    overflow: hidden;
    color: var(--main_bg);
    background-image: url("../../../public/themebg.png");
    background-position: bottom left;
}
#cir{
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(50% , 50%);
    width: 400px;
    height: 400px;
    border: 5px solid var(--main_bg);
    border-radius: 250px;
}
#icn{
    position: relative;
    width: 100px;
    height: 100px;
    background-color: var(--anti_theme);
    border-radius: 50px;
}
#icn::after , #icn::before{
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    left: 50%;
    top: 50%;
    transform: translate(-50% , -50%);
    background-color: var(--main_bg);
    z-index: -1;
}
#icn::before{
    rotate: 45deg;
    transform-origin: 0% 0%;
}
#inc{
    position: absolute;
    transform: translate(-50% , -50%);
    background-color: var(--main_bg);
    border-radius: 50px;
    transition: all 0.2s ease-in;
}
.darkmode{
    width: 80%;
    height: 80%;
    left: 30%;
    top: 30%;
}
.lightmode{
    width: 80%;
    height: 80%;
    left: 50%;
    top: 50%;
}
#theme_card h4{
    font-family: 'Montserrat', sans-serif;
    font-size: 4rem;
}
#font_sizes{
    display: flex;
    flex-flow: row;
    align-items: flex-start;
}
#font_sizes>div{
    width: calc(33.3333333333% - 20px);
    margin: 10px;
    padding: 10px;
    background-color: var(--main_bg);
    border-radius: 20px;
}
#font_sizes div>h1{
    font-size: large;
    padding: 5px 0;
    text-align: center;
    border-radius: 10px;
    background-color: #d3f4eb;
    color:var(--theme_color);
}
#font_sizes input{
    position: relative;
    appearance: none;
    width:100%; /* Full-width */
    height: 5px;
    border-radius: 10px;
    background-color: #ecf0f1;
    -webkit-appearance: none; /* Override default CSS styles */
    appearance: none;
    outline: none; /* Remove outline */
    -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
    transition: opacity 0.2s;
    cursor: pointer;
    margin: 30px 0;
}
#font_sizes input::-webkit-slider-thumb {
    position: relative;
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 15px; /* Set a specific slider handle width */
    height: 15px; /* Slider handle height */
    border-radius: 10px;
    background: var(--theme_color); /* Green background */
    cursor: pointer; /* Cursor on hover */
}
#font_sizes input::after{
    content: attr(max);
    position: absolute;
    bottom: 10px;
    right: 0;
}
#font_sizes input::before{
    content: attr(min);
    position: absolute;
    bottom: 10px;
    left: 0;
}
#font_sizes>div:first-child #display{
    text-align: right;
    font-family: 'Noto Naskh Arabic', serif;
    font-family: 'Roboto', sans-serif;
}
#font_sizes>div:not(1){
    font-family: 'Roboto', sans-serif;
}
#font_sizes .display{
    height: 100px;
    padding: 10px;
    border-radius: 20px;
    background-color: #ecf0f1;
    overflow-y: scroll;
    color: #000;
}
.display::-webkit-scrollbar{
    display: none;
}
#translations{
    background-color: var(--main_bg);
    border-radius: 20px;
    padding: 10px;
}
#trans_head{
    background-color: #d3f4eb;
    border-radius: 20px;
    padding: 5px 10px;
    color: var(--theme_color);
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}
#trans_head>p:last-child{
    background-color: var(--theme_color);
    padding: 10px;
    border-radius: 10px;
    color: var(--main_bg);
    font-weight: bolder;
    cursor: pointer;
}

#translations>table{
    width: 100%;
    padding: 0 5px;
}
#translations>table>tr{
    margin: 5px 0;
}
#translations>table>tr>td:last-child{
    background-color:var(--main_bg);
    color: red;
    text-align: center;
    cursor: pointer;
}
#translations>table>tr>td{
    padding: 0 5px;
}
#translations>table>tr>td:nth-child(1){
    width: 10%;
}
#translations>table>tr>td:nth-child(2){
        width: 30%;
}
#translations>table>tr>td:nth-child(3){
        width: 30%;
}
#translations>table>tr>td:nth-child(4){
        width: calc(30% - 40px);
}
#translations>table>tr>td:nth-child(5){
        width: 40px;
}

#trans_form{
 position: absolute;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 z-index: 2;
 background-color: rgba(255, 255, 255, 0.411);
 display: none;
 justify-content: center;
 align-items: center;
}
#trans_form .form{
    position: relative;
    min-width: 300px;
    padding: 20px 30px;
    background-color: var(--main_bg);
    border-radius: 10px;
    padding: 10px;   
}

.slc{
    padding: 10px;
    margin: 10px;
    background-color: var(--back_bg);
    cursor: pointer;
}
.opt{
    position: fixed;
    width: 250px;
    max-height: 200px;
    margin: 0 10px;
    background-color: var(--deep_bg);
    border-radius: 10px;
    overflow-y: scroll;
}
.opt_of{
    display:none;
}
.opt::-webkit-scrollbar{
    display: none;
}
.opt>p{
    margin: 5px 10px;
    cursor: pointer;
}
.btnu{
    border: none;
    border-radius: 20px;
    float: right;
    cursor: pointer;
    padding: 10px 20px;
    margin: 0 5px;
}
#add_tran{
    background-color: var(--theme_color);
    color: #fff;
}
#cncl{
    background-color: var(--back_bg);
    color: var(--main_text);
}


@media only screen and (max-width: 1200px){
}
@media only screen and (max-width: 700px){
    #cir{
        width: 300px;
        height: 300px;
    }
    #icn{
        width: 80px;
        height: 80px;
    }
    #theme_card h4{
        font-size: 3.5rem;
    }
}
@media only screen and (max-width: 550px){
    #theme_card h4{
        font-size: 2.5rem;
    }
}
@media only screen and (max-width: 450px){
    #translations{
        font-size: 10px;
        font-weight: 800;
    }
    #font_sizes{
        flex-flow: column;
    }
    #font_sizes>div{
        width: 100%;
        margin: 10px 0;
    }
}